.mr-video-container {
  &:not(:hover) {
    .mr-video-controls.opacity-0 {
      pointer-events: none;
    }
  }
}

.mr-video-controls {
  pointer-events: auto;
}
